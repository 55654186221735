up.compiler('.project_role', (projectRole) => {
  const deleteButton = projectRole.querySelector('#delete')

  deleteButton.addEventListener('click', () => {
    if (confirm('Diese Projektrolle entfernen?')) {
      projectRole.querySelector('.delete').value = '1'
      up.element.hide(projectRole)
    }
  })
})
