class PlannerBackend {
  constructor(planner) {
    this.planner = planner
  }

  update(attributes) {
    this.ajaxRequest('put', rootUrl() + '/tasks/' + attributes.id, attributes).then(this.ajaxApplyDelta.bind(this))
  }

  fetchBudgetInfo(attributes) {
    this.ajaxRequest('get', rootUrl() + '/budgets/summary/' + attributes.get('budget_id'), attributes)
      .then((response) => {
        const data = JSON.parse(response.text)
        this.planner.updateBudgetInfo(data.budget_info, data.timestamp)
      })
  }

  create(attributes) {
    this.ajaxRequest('post', rootUrl() + '/tasks', attributes).then(this.ajaxApplyDelta.bind(this))
  }

  destroy(task) {
    this.ajaxRequest('delete', rootUrl() + '/tasks/' + task.dataset.id, {}).then(this.ajaxApplyDelta.bind(this))
  }

  ajaxRequest(method, url, params) {
    params.angle = this.planner.angle

    const requestOptions = {
      method,
      params,
    }

    return up.request(url, requestOptions).catch(this.ajaxFailure.bind(this))
  }

  ajaxApplyDelta(response) {
    const data = JSON.parse(response.text)
    for (const day of data.days) {
      this.planner.updateDay(day, day.content)
    }
    this.planner.updateTotals(data.totals)
    this.planner.notifySelectedBudgetChange()
  }

  ajaxFailure(response) {
    alert('Could not save: ' + response.text)
  }
}

class PlannerRenderHelper {
  constructor(planner) {
    this.planner = planner
    this.data = planner.data
    this.icons = planner.icons
  }

  formatTotal(total) {
    return total ? Math.round(total) : '-'
  }

  taskBudget(task) {
    return this.taskProject(task).budgets[task.dataset.budget_id]
  }

  taskProject(task) {
    return this.data.projects[task.dataset.project_id]
  }

  taskProjectRate(task) {
    const id = task.dataset.project_rate_id
    return id ? this.taskProject(task).project_rates[id] : null
  }

  taskAccountingMethod(task) {
    return task.dataset.accounting_method
  }

  taskUser(task) {
    return this.data.users[task.dataset.user_id]
  }

  taskTooltip(task) {
    const project = this.taskProject(task)
    const budget = this.taskBudget(task)
    const projectRate = this.taskProjectRate(task)
    const user = this.taskUser(task)
    let tooltip = ''

    tooltip += '<table>'
    const comment = task.dataset.comment
    if (comment) {
      tooltip += '<tr><th><strong>Kommentar</strong></th><td><strong>' + _.escape(comment) + '</strong></td></tr>'
    }
    tooltip += '<tr><th>Mitarbeiter</th><td>' + _.escape(user.name) + '</td></tr>'
    tooltip += '<tr><th>Stundensatz</th>'
    if (projectRate) {
      tooltip += '<td>' + _.escape(projectRate.name) + '</td>'
    } else if (this.data.highlight_tasks_without_rate) {
      tooltip += '<td class="text-danger">keiner</td>'
    }
    tooltip += '</tr>'
    tooltip += '<tr><th>Projekt</th><td>' + _.escape(project.name) + '</td></tr>'

    if (budget.tentative) {
      tooltip += '<tr><th>Budget</th><td class="text-danger">' + 'Vorläufig: ' + _.escape(budget.name) + '</td></tr>'
    } else {
      tooltip += '<tr><th>Budget</th><td>' + _.escape(budget.name) + '</td></tr>'
    }

    if (!this.planner.readOnly()) {
      tooltip += '<tr><td colspan="2"><em>Zum Bearbeiten rechtsklicken oder doppelklicken</em></td></tr>'
      tooltip += '<tr><td colspan="2"><em>Zum Löschen "Strg" + doppelklicken</em></td></tr>'
    }
    return tooltip
  }

  optionTag(label, value, selected) {
    return '<option value="' + value + '" ' + (String(selected) === String(value) ? 'selected="selected" ' : '') + '>' + _.escape(label) + '</option>'
  }

  optgroupTag(label, optionsString) {
    if (optionsString.length === 0) return '' // Hide optgroup with no options
    return '<optgroup label="' + label + '">' + optionsString + '</optgroup>'
  }

  textFieldTag(attr, value) {
    return '<input name="' + attr + '" class="form-control" id="' + attr + '" type="text" value="' + (value || '') + '"/>'
  }

  hourFloorsOptions(selected) {
    selected = Number(selected)
    return this.data.hour_floors.map(function(pair) {
      return this.optionTag(pair[1], pair[0], selected)
    }.bind(this)).join('')
  }

  hourFracsOptions(selected) {
    selected = Number(selected)
    return this.data.hour_fracs.map(function(pair) {
      return this.optionTag(pair[1], pair[0], selected)
    }.bind(this)).join('')
  }

  availableAccountingMethods(projectId, projectRateId) {
    const project = this.data.projects[projectId]
    const projectRate = project.project_rates[projectRateId]
    // a task may have no project rate
    const available = (projectRate && projectRate.modes) || 'none'

    return _.pick(this.data.accounting_methods, available)
  }

  accountingMethodOptions(projectId, projectRateId, selected) {
    const available = this.availableAccountingMethods(projectId, projectRateId)

    return _.collect(available, function(value, key) {
      return this.optionTag(value, key, selected)
    }, this).join('')
  }

  budgetOptions(project, selected, closedOptionsFirst) {
    const active = this.optgroupTag('Aktive Budgets', this.collectionOptions(project.active_budget_ids, project.budgets, selected))
    let closed = this.optgroupTag('Geschlossene Budgets', this.collectionOptions(project.closed_budget_ids, project.budgets, selected))

    // add class to closed options
    closed = closed.replace(/<option /g, '<option class="text-secondary" ')

    if (closedOptionsFirst) {
      // Show closed budgets first so to select an active budget
      // you have to travel only a shorter distance with your pointer
      return closed + active
    } else {
      return active + closed
    }
  }

  projectRateOptions(project, selected, closedOptionsFirst) {
    const active = this.optgroupTag('Aktive Stundensätze', this.collectionOptions(project.active_project_rate_ids, project.project_rates, selected))
    let closed = this.optgroupTag('Geschlossene Stundensätze', this.collectionOptions(project.closed_project_rate_ids, project.project_rates, selected))

    // add class to closed options
    closed = closed.replace(/<option /g, '<option class="text-secondary" ')

    if (closedOptionsFirst) {
      return closed + active + this.optionTag('(kein Stundensatz)')
    } else {
      return this.optionTag('(kein Stundensatz)') + active + closed
    }
  }

  // this function is tailored for budgets or project rates
  collectionOptions(wantedIds, collection, selected) {
    return wantedIds.map(function(id) {
      const item = collection[id]
      return this.optionTag(item.name, item.id, selected)
    }.bind(this)).join('')
  }

  task(task) {
    return '<div class="calendar--task-label">' +
      _.escape(this.planner.taskLabel(task).substr(0, 3)) +
      '</div>' +
      '<div>' +
      this.taskUnits(task) +
      '</div>'
  }

  taskUnits(task) {
    // TaskHelper#short_hours returns "" when the tasks hours are 0
    const hours = task.dataset.short_hours || 0.0
    let days

    function muted(text) {
      return '<span class="text-muted">' + text + '</span>'
    }

    switch (task.dataset.accounting_method) {
      case 'hourly':
        return hours + muted('h')

      case 'daily':
        days = parseFloat(task.dataset.hours) / 8.0
        days = +days.toFixed(2) // round
        days = (days === 0.5) ? '½' : days
        days = days.toString().replace(/^0+/g, '') // strip leading zeros
        return days + muted('d')

      case 'packaged':
        return hours + muted('x')

      case 'none':
        return muted('(') + hours + muted('h)')
    }
  }

  taskEditForm(task) {
    const project = this.taskProject(task)

    const form = document.createElement('form')
    form.id = 'task_form'
    form.action = '#'
    form.dataset.task_id = task.dataset.id
    form.addEventListener('submit', function(e) {
      e.preventDefault()
      this.planner.editForm.save()
    }.bind(this))

    form.innerHTML =
      '<input type="hidden" id="project_id" value="' + task.dataset.project_id + '">' +
      '<table class="form-table -narrow-labels w-auto">' +
        '<tbody>' +
          '<tr>' +
            '<th>' +
              '<label for="hours_floor">Zeit</label>' +
            '</th>' +
            '<td>' +
              '<div class="input-group">' +
                '<select name="hours_floor" class="form-select flex-grow-0 w-auto" id="hours_floor">' + this.hourFloorsOptions(task.dataset.hours_floor) + '</select>' +
                '<select name="hours_frac" class="form-select flex-grow-0 w-auto" id="hours_frac">' + this.hourFracsOptions(task.dataset.hours_frac) + '</select>' +
                '<div class="input-group-text">Stunden</div>' +
              '</div>' +
            '</td>' +
          '</tr>' +
          '<tr>' +
            '<th>' +
              '<label for="budget_id">Budget</label>' +
            '</th>' +
            '<td>' +
              '<select name="budget_id" class="form-select" id="budget_id">' +
                this.budgetOptions(project, task.dataset.budget_id) +
              '</select>' +
            '</td>' +
          '</tr>' +
          '<tr>' +
            '<th>' +
              '<label for="project_rate_id">Stundensatz</label>' +
            '</th>' +
            '<td>' +
              '<select name="project_rate_id" class="form-select" id="project_rate_id" onchange="Planner.notifyProjectRateChange(Planner.editForm.form())">' +
                this.projectRateOptions(project, task.dataset.project_rate_id) +
              '</select>' +
            '</td>' +
          '</tr>' +
          '<tr>' +
            '<th>' +
              '<label for="accounting_method">Abrechnung</label>' +
            '</th>' +
            '<td>' +
              '<select name="accounting_method" class="form-select" id="accounting_method">' +
                this.accountingMethodOptions(task.dataset.project_id, task.dataset.project_rate_id, task.dataset.accounting_method) +
              '</select>' +
            '</td>' +
          '</tr>' +
          '<tr>' +
            '<th><label for="comment">Kommentar</label></th>' +
            '<td>' + this.textFieldTag('comment', task.dataset.comment) + '</td>' +
          '</tr>' +
        '</tbody>' +
      '</table>' +
      '<p class="d-flex justify-content-between">' +
        '<button type="submit" class="btn btn-outline-primary">' + this.icons.save + '</button>' +
        '<a href="#" onclick="Planner.editForm.destroyTask(); return false;" class="btn btn-outline-danger" id="delete_link">Löschen</a>' +
      '</p>'

    return form
  }
}

class PlannerTaskEditForm {
  constructor(planner) {
    this.planner = planner
    this.renderHelper = planner.renderHelper
  }

  save() {
    this.planner.taskUpdated(this.values())
    this.close()
  }

  destroyTask() {
    const formTask = this.planner.taskById(this.values().id)
    this.planner.destroyTask(formTask)
  }

  values() {
    const form = this.form()
    return {
      id: form.dataset.task_id,
      angle: this.planner.angle,
      hours_frac: form.querySelector('#hours_frac').value,
      hours_floor: form.querySelector('#hours_floor').value,
      project_rate_id: form.querySelector('#project_rate_id') ? form.querySelector('#project_rate_id').value : null,
      budget_id: form.querySelector('#budget_id') ? form.querySelector('#budget_id').value : null,
      accounting_method: form.querySelector('#accounting_method') ? form.querySelector('#accounting_method').value : null,
      comment: form.querySelector('#comment').value,
    }
  }

  form() {
    return document.getElementById('task_form')
  }

  close() {
    document.querySelectorAll('.popover').forEach(function(form) {
      form.remove()
    })
  }

  /*
   * Data attributes are read from a '.calendar--task'.
   * Tasks (and their attributes) are rendered in tasks_helper.rb.
   */
  render(task) {
    this.close()
    const container = document.createElement('div')
    container.classList.add('popover', 'popover-wide')
    container.id = 'task_form_container'
    const title = document.createElement('div')
    title.classList.add('popover-header', 'd-flex', 'justify-content-between')
    title.innerHTML =
      '<span>Würfel bearbeiten</span>' +
      '<a href="#" onclick="Planner.editForm.close(); return false;" id="close_button">' + this.planner.renderHelper.icons.close_form + '</a>'
    const form = this.renderHelper.taskEditForm(task)
    form.classList.add('popover-body')

    container.appendChild(title)
    container.appendChild(form)
    document.body.appendChild(container)

    const taskPos = task.getBoundingClientRect()
    container.style.left = Math.min(Math.max(5, taskPos.left + 20 - 0.5 * container.offsetWidth), document.body.offsetWidth - container.offsetWidth - 5) + 'px'
    container.style.top = Math.max(5, taskPos.top - container.offsetWidth - 17) + 'px'
  }
}

class PlannerNewTaskForm {
  constructor(planner) {
    this.planner = planner
    this.renderHelper = planner.renderHelper
  }

  form() {
    return document.querySelector('#new_task_form')
  }

  values() {
    return new FormData(this.form())
  }

  projectSelect() {
    return this.form().querySelector('select#project_id')
  }

  budgetSelect() {
    return this.form().querySelector('select#budget_id')
  }

  projectRateSelect() {
    return this.form().querySelector('select#project_rate_id')
  }

  budgetInfo() {
    return this.form().querySelector('#budget_info')
  }

  getSelectedProjectId() {
    return this.projectSelect().value
  }

  setSelectedProjectId(projectId) {
    const selectTag = this.projectSelect()
    selectTag.value = projectId
    this.triggerChange(selectTag)
  }

  triggerChange(input) {
    const event = new Event('tail.select:source:change', { bubbles: true, cancelable: true })
    input.dispatchEvent(event)
  }

  setFieldsFromTask(task) {
    if (this.planner.angle === 'by_project') {
      return
    }
    const project = this.renderHelper.taskProject(task)
    const budget = this.renderHelper.taskBudget(task)
    const projectRate = this.renderHelper.taskProjectRate(task)
    const accountingMethod = this.renderHelper.taskAccountingMethod(task)

    this.setSelectedProjectId(project.id)
    this.replaceBudgetSelect(project, budget.id)
    this.replaceProjectRateSelect(project, projectRate && projectRate.id)

    this.planner.notifySelectedBudgetChange()
    this.planner.notifyProjectRateChange(this.form(), accountingMethod)
  }

  setFieldsFromProject(project) {
    this.setSelectedProjectId(project.id)
    const selectedBudgetId = project.active_budget_ids.concat(project.closed_budget_ids)[0]
    this.replaceBudgetSelect(project, selectedBudgetId)
    const selectedProjectRateId = project.active_project_rate_ids.concat(project.closed_project_rate_ids)[0]
    this.replaceProjectRateSelect(project, selectedProjectRateId)
    this.planner.notifySelectedBudgetChange()
    this.planner.notifyProjectRateChange(this.form())
  }

  replaceBudgetSelect(project, selectedBudgetId) {
    // update DOM
    const budgetSelect = this.budgetSelect()
    budgetSelect.innerHTML = this.renderHelper.budgetOptions(project, selectedBudgetId, 'closedOptionsFirst')
    // set selected property
    const option = budgetSelect.querySelector('option[value="' + selectedBudgetId + '"]')
    if (option !== null) {
      setSelectedOption(budgetSelect, option)
    }
  }

  replaceProjectRateSelect(project, selectedProjectRateId) {
    // update DOM
    const projectRateSelect = this.projectRateSelect()
    projectRateSelect.innerHTML = this.renderHelper.projectRateOptions(project, selectedProjectRateId, 'closedOptionsFirst')
    // set selected property
    const option = projectRateSelect.querySelector('option[value="' + selectedProjectRateId + '"]')
    if (option !== null) {
      setSelectedOption(projectRateSelect, option)
    }
  }
}

class PlannerBase {
  constructor(plannerViewJSON, icons) {
    this.table = document.querySelector('.calendar')
    this.data = plannerViewJSON
    this.icons = icons
    this.renderHelper = new PlannerRenderHelper(this)
    this.editForm = new PlannerTaskEditForm(this)
    this.newTaskForm = new PlannerNewTaskForm(this)
    this.backend = new PlannerBackend(this)

    this.observeWidth('th.calendar--heading.-top-left', '--left-column-width')
  }

  observeWidth(selector, variableName) {
    const element = this.table.querySelector(selector)
    if (element) {
      new ResizeObserver(this.setColumnWidth.bind(this, element, variableName)).observe(element)
    }
  }

  setColumnWidth(element, variableName) {
    this.table.style.setProperty(variableName, element.offsetWidth + 'px')
  }

  rootOrTable(root) {
    return root || this.table
  }

  taskById(id) {
    return this.table.querySelector('.calendar--task[data-id="' + id + '"]')
  }

  tasks(root) {
    return this.rootOrTable(root).querySelectorAll('.calendar--task')
  }

  days() {
    return this.table.querySelectorAll('.calendar--day.-cell')
  }

  dayCells() {
    return this.table.querySelectorAll('.calendar--cell')
  }

  dropTask(task, day) {
    Tooltip.hide()
    this.editForm.close()
    let success = false
    const oldDay = task.closest('[data-day]')
    if (day && day !== oldDay) {
      this.moveTask(task, day)
      success = true
    }
    return success
  }

  moveTask(task, day) {
    const attributes = this.attributesForDay(day)
    attributes.id = task.dataset.id
    this.disableDay(day)
    this.backend.update(attributes)

    task.remove() // The task for the new day will be inserted by this.backend.update
  }

  disableDay(dayOrTask) {
    const day = dayOrTask.closest('.calendar--day')
    day.classList.add('-updating')
  }

  taskUpdated(values) {
    const task = this.taskById(values.id)
    this.outdateBudgetInfo()
    this.disableDay(task)
    this.backend.update(values)
  }

  dayUnderMouse(event) {
    const elements = document.elementsFromPoint(event.clientX, event.clientY)
    const day = elements.find(function(element) {
      return element.classList.contains('calendar--day')
    })
    return day
  }

  isTask(object) {
    return object.classList && object.classList.contains('calendar--task')
  }

  openTaskForm(eventOrTask) {
    let task = this.isTask(eventOrTask) ? eventOrTask : Event.element(eventOrTask)
    if (!task.classList.contains('calendar--task')) {
      task = task.closest('.calendar--task')
    }
    this.editForm.render(task)
    this.newTaskForm.setFieldsFromTask(task)
  }

  // This is called from the server!
  updateDay(attributes, html) {
    const day = this.dayForAttributes(attributes)
    day.innerHTML = html
    day.classList.remove('-updating')
    this.connectDay(day)
  }

  connectDay(day) {
    this.connectDayCell(day.children[0])

    if (!this.readOnly()) {
      day.addEventListener('dragenter', (event) => {
        if (event.dataTransfer.types.includes('application/x-planner-task')) {
          event.preventDefault()
          day.classList.add('-drag-over')
        }
      })

      day.addEventListener('dragover', (event) => {
        if (event.dataTransfer.types.includes('application/x-planner-task')) {
          event.preventDefault()
          day.classList.add('-drag-over')
        }
      })

      day.addEventListener('dragleave', (event) => {
        day.classList.remove('-drag-over')
      })

      day.addEventListener('drop', (event) => {
        const id = event.dataTransfer.getData('application/x-planner-task')
        const task = this.taskById(id)
        if (task) {
          this.dropTask(task, day)
          event.preventDefault()
        }
      })
    }
  }

  connectDayCell(cell) {
    const tasks = this.tasks(cell)
    tasks.forEach(function(task) {
      this.connectTask(task)
    }.bind(this))
  }

  newTaskClicked(button) {
    if (this.validateHours() && this.isTimeAvailable(button)) {
      const day = button.closest('.calendar--day')
      const attributes = Object.assign({}, this.attributesForDay(day), Object.fromEntries(this.newTaskForm.values()))
      if (document.querySelector('#check_availability').checked) {
        this.reduceHoursAccordingToAvailability(day, attributes)
      }
      this.disableDay(day)
      this.outdateBudgetInfo()
      this.backend.create(attributes)
    }
  }

  validateHours() {
    const values = this.newTaskForm.values()
    if (values.get('hours_floor') === '0' && values.get('hours_frac') === '0') {
      alert('Bitte die Stundenzahl auswählen.')
      return false
    }
    return true
  }

  isTimeAvailable(button) {
    if (document.querySelector('#check_availability').checked) {
      const dayNode = button.closest('.calendar--day')

      const availableHours = this.remainingAvailability(dayNode)

      if (parseFloat(availableHours) <= 0.0) {
        alert('Planungswürfel kann nicht erstellt werden!\nMitarbeiter hat keine freie Arbeitszeit mehr zur Verfügung.')
        return false
      }
    }
    return true
  }

  reduceHoursAccordingToAvailability(day, attributes) {
    const taskHours = parseFloat(attributes.hours_floor) + parseFloat(attributes.hours_frac)

    const availableHours = this.remainingAvailability(day)

    this.updateHoursAndFractions(Math.min(taskHours, availableHours), attributes)
  }

  updateHoursAndFractions(totalTime, attributes) {
    const hoursFloor = Math.floor(totalTime).toString()
    let hoursFrac = totalTime - hoursFloor
    if (Number.isInteger(hoursFrac)) {
      hoursFrac = hoursFrac + '.0'
    } else {
      hoursFrac = hoursFrac.toString()
    }
    attributes.hours_floor = hoursFloor
    attributes.hours_frac = hoursFrac
  }

  remainingAvailability(dayNode) {
    const availability = dayNode.dataset.availability
    const taskNodes = dayNode.querySelector('.calendar--cell').children
    let availableHours = availability
    for (const task of taskNodes) {
      if (task.hasAttribute('data-hours')) {
        availableHours = availableHours - parseFloat(task.dataset.hours)
      }
    }
    return availableHours
  }

  readOnly() {
    return this.data.read_only
  }

  connectTask(task) {
    this.renderTask(task)
    this.connectTaskTooltip(task)
    if (!this.readOnly()) {
      this.makeTaskDraggable(task)
      if (task.dataset.freshly_created) {
        this.openTaskForm(task)
      }
    }
  }

  makeTaskDraggable(task) {
    task.draggable = 'true'

    task.addEventListener('dragstart', (event) => {
      event.dataTransfer.setData('application/x-planner-task', task.dataset.id)
      event.dataTransfer.effectAllowed = 'move'
      Tooltip.hide()
    })
  }

  connectTaskTooltip(task) {
    task.addEventListener('mouseover', function(event) {
      Tooltip.show(task, event, this.renderHelper.taskTooltip(task))
    }.bind(this))

    task.addEventListener('mouseout', Tooltip.hide.bind(Tooltip))
  }

  connect(totals) {
    this.connectStartTime = new Date().getTime()
    this.table.addEventListener('click', this.bodyClicked.bind(this))
    this.table.addEventListener('dblclick', this.bodyDoubleClicked.bind(this))
    this.table.addEventListener('contextmenu', this.bodyRightClicked.bind(this))
    document.addEventListener('keyup', this.keyPressed.bind(this))
    this.setupWorker()
    this.worker.queue(this.updateTotals.bind(this, totals))
    this.queueDayCellConnections()
    this.worker.start()
  }

  setupWorker() {
    this.worker = new Worker({
      afterUpdate: function(current, total) {
        const percentLoaded = Math.round(100 * current / total)
        const progressBar = document.querySelector('.progress-bar')
        progressBar.style.width = percentLoaded.toString() + '%'
        progressBar.setAttribute('aria-valuenow', current)
        progressBar.setAttribute('aria-valuemax', total)
      },
      afterFinish: function() {
        this.table.classList.remove('hidden')
        document.querySelector('.progress').remove()
      }.bind(this),
    })
  }

  queueDayCellConnections() {
    const days = this.days()
    const jobSize = 100
    for (let offset = 0; offset < days.length; offset += jobSize) {
      this.worker.queue(this.connectDaySlice.bind(this, days, offset, jobSize))
    }
  }

  connectDaySlice(days, offset, length) {
    for (let i = offset; i < Math.min(days.length, offset + length); i++) {
      this.connectDay(days[i])
    }
  }

  bodyClicked(event) {
    const newTask = this.findEventSource(event, '.calendar--new-task')
    if (newTask) {
      this.newTaskClicked(newTask)
    }
  }

  bodyDoubleClicked(event) {
    const task = this.findEventSource(event, '.calendar--task')
    if (task && !this.readOnly()) {
      if (event.shiftKey || event.ctrlKey) {
        this.destroyTask(task)
      } else {
        this.bodyRightClicked(event)
      }
    }
  }

  bodyRightClicked(event) {
    const task = this.findEventSource(event, '.calendar--task')

    if (task && !this.readOnly()) {
      event.preventDefault()
      this.openTaskForm(task)
    }
  }

  // hotkey "ESC" for closing task forms
  keyPressed(event) {
    if (event.code === 'Escape') {
      this.editForm.close()
    }
  }

  destroyTask(task) {
    if (task.destroyed) {
      return
    }

    this.editForm.close()
    this.outdateBudgetInfo()
    this.backend.destroy(task)
    this.disableDay(task)
    task.destroyed = true
    task.remove()
  }

  findEventSource(event, selector) {
    const element = event.target
    return element.closest(selector)
  }

  updateTotals(totals) {
    this.table.querySelectorAll('.calendar--total.-column').forEach(function(td) {
      const total = this.columnTotal(totals, td)
      td.innerText = this.renderHelper.formatTotal(total)
    }.bind(this))

    this.table.querySelectorAll('.calendar--total.-row-hours').forEach(function(td) {
      const total = this.rowTotal(totals, td)
      td.innerText = this.renderHelper.formatTotal(total)
    }.bind(this))

    this.table.querySelectorAll('.calendar--total.-row-unplanned-days').forEach(function(td) {
      const total = this.rowTotalUnplannedDays(totals, td)
      td.innerText = this.renderHelper.formatTotal(total)
    }.bind(this))

    this.table.querySelector('.calendar--total.-all-hours').innerText = this.renderHelper.formatTotal(totals.hours)
    this.table.querySelector('.calendar--total.-all-unplanned-days').innerText = this.renderHelper.formatTotal(totals.unplanned_days)
  }

  columnTotal(totals, td) {
    return totals.hours_by_day[td.dataset.day]
  }

  colorTask(task) {
    task.style.setProperty('--task-background-color', task.dataset.color)
  }

  dimTaskWithoutRate(task) {
    if (task.dataset.accounting_method === 'none' && this.data.dim_tasks_without_rate) {
      task.classList.add('-no-moneys')
    }
  }

  setTaskTentative(task) {
    const marker = document.createElement('div')
    marker.classList.add('calendar--task-marker', '-tentative')
    marker.innerHTML = '<svg viewBox="0 0 5 5"><path d="M5,1V0H4Z"/></svg>'
    task.appendChild(marker)
  }

  setTaskComment(task) {
    const marker = document.createElement('div')
    marker.classList.add('calendar--task-marker', '-comment')
    marker.innerHTML = '<svg viewBox="0 0 5 5"><path d="M4,5H5V4Z"/></svg>'
    task.appendChild(marker)
  }

  renderTask(task) {
    const tentative = this.renderHelper.taskBudget(task).tentative
    task.innerHTML = this.renderHelper.task(task)
    this.colorTask(task)
    this.dimTaskWithoutRate(task)
    if (tentative) {
      this.setTaskTentative(task)
    }
    if (task.dataset.comment) {
      this.setTaskComment(task)
    }
  }

  notifyProjectRateChange(form, accountingMethod) {
    const accountingMethodSelect = form.querySelector('select#accounting_method')
    const projectRateId = form.querySelector('select#project_rate_id').value
    const projectId = form.querySelector('#project_id').value
    const project = this.data.projects[projectId]

    if (accountingMethod === undefined) {
      accountingMethod = project.accounting_method
    }
    const options = this.renderHelper.accountingMethodOptions(project.id, projectRateId, accountingMethod)
    accountingMethodSelect.innerHTML = options
  }
}

class PlannerByUser extends PlannerBase {
  constructor(view, icons) {
    super(view, icons)
    this.angle = 'by_user'
    this.latestTimestampSeen = 0

    if (!this.readOnly()) {
      const project = this.getSelectedProjectFromSelectTag()
      this.newTaskForm.setFieldsFromProject(project)
      this.notifySelectedBudgetChange()
    }
  }

  rowTotal(totals, td) {
    return totals.hours_by_user_id[td.dataset.user_id]
  }

  rowTotalUnplannedDays(totals, td) {
    return totals.unplanned_days_by_user_id[td.dataset.user_id]
  }

  taskLabel(task) {
    return this.renderHelper.taskBudget(task).short_name
  }

  dayForAttributes(attributes) {
    return this.table.querySelector('tr[data-user_id="' + attributes.user_id + '"]>td[data-day="' + attributes.day + '"]')
  }

  attributesForDay(day) {
    const row = day.closest('tr')
    return {
      user_id: row.dataset.user_id,
      day: day.dataset.day,
    }
  }

  getSelectedProjectFromSelectTag() {
    const projectId = this.newTaskForm.getSelectedProjectId()
    return this.data.projects[projectId]
  }

  handleNewTaskFormProjectChange() {
    const project = this.getSelectedProjectFromSelectTag()
    this.newTaskForm.setFieldsFromProject(project)
  }

  /*
   * The budget info must not be overwritten with outdated responses, e.g. when
   * a response is overtaken by a newer one.
   * Achieve this by comparing the timestamp received in the update call with
   * the most recent timestamp the server has sent.
   */
  updateBudgetInfo(info, timestamp) {
    if (this.latestTimestampSeen < timestamp) {
      this.latestTimestampSeen = timestamp
      const budgetInfo = this.newTaskForm.budgetInfo()
      budgetInfo.innerHTML = info
      budgetInfo.classList.remove('text-muted')
    }
  }

  notifySelectedBudgetChange() {
    this.outdateBudgetInfo()
    this.backend.fetchBudgetInfo(this.newTaskForm.values())
  }

  outdateBudgetInfo() {
    this.newTaskForm.budgetInfo().classList.add('text-muted')
  }
}

// The angle 'by_project' is never used by us.
class PlannerByProject extends PlannerBase {
  constructor(view, icons) {
    super(view, icons)
    this.angle = 'by_project'
    this.observeWidth('th.calendar--heading.-project', '--project-column-width')
  }

  rowTotal(totals, td) {
    return totals.hours_by_budget_id[td.dataset.budget_id]
  }

  dayForAttributes(attributes) {
    return this.table.querySelector('tr[data-budget_id="' + attributes.budget_id + '"]>td[data-day="' + attributes.day + '"]')
  }

  attributesForDay(day) {
    const row = day.closest('tr')
    return {
      project_id: row.dataset.project_id,
      budget_id: row.dataset.budget_id,
      day: day.dataset.day,
    }
  }

  taskLabel(task) {
    return this.renderHelper.taskUser(task).code
  }

  // If viewing by project, there is nothing to do
  outdateBudgetInfo() {}
}

window.PlannerByUser = PlannerByUser
window.PlannerByProject = PlannerByProject
