const InboxTimer = {

  nextStop: null,
  timer: null,
  offset: 20,

  connect: function(timer) {
    const readoutElement = document.getElementById('inbox_retrieve_interval')
    this.timer = timer
    if (this.timer && readoutElement) {
      const interval = parseInt(readoutElement.innerHTML)
      this.nextStop = this.calculateNextStop(interval)
      this.updateTime()
      setInterval(this.updateTime.bind(this), 500)
    }
  },

  calculateNextStop: function(interval) {
    const offset = this.offset * 1000
    const activationTime = new Date()
    const pastBins = Math.floor(activationTime.getTime() / 1000 / interval)
    const futureBins = pastBins + 1
    return new Date(futureBins * 1000 * interval + offset)
  },

  updateTime: function() {
    const currentDate = new Date()
    const difference = Math.floor((this.nextStop.getTime() - currentDate.getTime()) / 1000)
    if (difference > 0) {
      const minutes = this.numberToTimeUnit(Math.floor(difference / 60))
      const seconds = this.numberToTimeUnit(difference % 60)
      this.timer.innerHTML = minutes + ':' + seconds
    } else {
      this.timer.innerHTML = '00:00'
    }
  },

  numberToTimeUnit: function(number) {
    return number < 10 ? '0' + number : number
  },
}

up.compiler('#inbox_timer', InboxTimer.connect.bind(InboxTimer))
