up.compiler('.create_invoice_from_suggestion', (element) => {
  element.addEventListener('click', (e) => {
    if (element.classList.contains('disabled')) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const confirmed = confirm('Rechnung erzeugen?')

    if (confirmed) {
      const icon = element.querySelector('.icon--image')
      icon.classList.remove('text-success')
      icon.classList.add('text-muted')

      element.classList.add('disabled')
      element.setAttribute('title', 'Rechnung wurde bereits erzeugt')
      element.style.cursor = 'help'
    } else {
      e.preventDefault()
      e.stopPropagation()
    }
  })
})
