const UberBar = {
  hoursForRecentDays: {

    showAll: function() {
      document.querySelectorAll('.recent_day_entry').forEach(function(element) {
        up.element.show(element)
      })
      document.getElementById('hours_for_recent_days_show_all').classList.add('hidden')
      document.getElementById('hours_for_recent_days_show_few').classList.remove('hidden')
    },

    showFew: function() {
      let counter = 0
      document.querySelectorAll('.recent_day_entry').forEach(function(element) {
        counter++
        if (counter > 2) up.element.hide(element)
      })
      document.getElementById('hours_for_recent_days_show_all').classList.remove('hidden')
      document.getElementById('hours_for_recent_days_show_few').classList.add('hidden')
    },

  },

  upcomingTasks: {

    showAll: function() {
      document.querySelectorAll('.task-list-day').forEach(function(element) {
        up.element.show(element)
      })
      document.getElementById('upcoming_tasks_show_all').classList.add('hidden')
      document.getElementById('upcoming_tasks_show_few').classList.remove('hidden')
    },

    showFew: function() {
      let counter = 0
      document.querySelectorAll('.task-list-day').forEach(function(element) {
        counter++
        if (counter > 2) up.element.hide(element)
      })
      document.getElementById('upcoming_tasks_show_all').classList.remove('hidden')
      document.getElementById('upcoming_tasks_show_few').classList.add('hidden')
    },

  },
}

up.compiler('#hours_for_recent_days_container', function(container) {
  const showAllHoursLink = container.querySelector('#hours_for_recent_days_show_all a')
  const showFewHoursLink = container.querySelector('#hours_for_recent_days_show_few a')

  UberBar.hoursForRecentDays.showFew()

  up.on(showAllHoursLink, 'click', UberBar.hoursForRecentDays.showAll)
  up.on(showFewHoursLink, 'click', UberBar.hoursForRecentDays.showFew)
})

up.compiler('#upcoming_tasks_container', function(container) {
  const showAllTasksLink = container.querySelector('#upcoming_tasks_show_all a')
  const showFewTasksLink = container.querySelector('#upcoming_tasks_show_few a')

  UberBar.upcomingTasks.showFew()

  up.on(showAllTasksLink, 'click', UberBar.upcomingTasks.showAll)
  up.on(showFewTasksLink, 'click', UberBar.upcomingTasks.showFew)
})
