const Stopwatches = {

  startTime: null,

  parseStartTime: function() {
    const totalElement = document.getElementById('running_stopwatch_total')
    if (totalElement) {
      const totalSeconds = parseInt(totalElement.innerHTML)
      this.startTime = new Date().getTime() - totalSeconds * 1000 - this.offset * 1000
      totalElement.remove()
    }
  },

  parseOffset: function() {
    const offsetElement = document.getElementById('running_stopwatch_offset')
    if (offsetElement) {
      this.offset = Math.min(parseInt(offsetElement.innerHTML), 0)
      offsetElement.remove()
    }
  },

  updateDisplay: function() {
    const displayElement = document.getElementById('running_stopwatch_display')
    if (displayElement) {
      const elapsedSeconds = (new Date().getTime() - this.startTime) * 0.001
      if (elapsedSeconds > 0) {
        const hoursStr = this.leadingZero(Math.floor(elapsedSeconds / 3600), 1)
        const minutesStr = this.leadingZero(Math.floor((elapsedSeconds / 60) % 60), 2)
        const secondsStr = this.leadingZero(Math.floor(elapsedSeconds % 60), 2)
        const display = hoursStr + ':' + minutesStr + ':' + secondsStr
        displayElement.innerHTML = display
      }
    }
  },

  leadingZero: function(s, len) {
    s = s + ''
    while (s.length < len) {
      s = '0' + s
    }
    return s
  },

  update: function() {
    this.parseOffset()
    this.parseStartTime()
    this.updateDisplay()
  },

  reloading: function() {
    document.getElementById('stopwatches').classList.add('reloading')
  },

  reloaded: function() {
    document.getElementById('stopwatches').classList.remove('reloading')
  },

}

document.addEventListener('DOMContentLoaded', function() {
  setInterval(Stopwatches.update.bind(Stopwatches), 500)
})

up.on('up:link:follow', '[data-stopwatch-remote]', function(event, button) {
  Stopwatches.reloading()
  event.renderOptions.onFinished = Stopwatches.reloaded.bind(Stopwatches)
})
