function initActivityForm(form) {

  let previousAccountingMethod = getAccountingMethod()
  let showMore = false
  const selectorsToUpdateFromServer = new Set()
  let serverUpdateTimer = null

  // Returns the form's child element with the given selector.
  function getElement(selector) {
    return form.querySelector(selector)
  }

  function getFieldSelector(name) {
    return `[name="activity[${name}]"]`
  }

  function getCheckboxSelector(name) {
    return getFieldSelector(name) + '[value="1"]'
  }

  // Returns the field with the given [name] attribute.
  function getField(name) {
    const field = getElement(getFieldSelector(name))
    if (!field) {
      throw new Error(`Could not find field for "${name}"`)
    }
    return field
  }

  // Returns the value of the field with the given [name] attribute.
  function getValue(name) {
    return getField(name).value
  }

  // Sets the field with the given [name] attribute to the given value.
  // Optionally emits a change event.
  function setValue(name, value, options = {}) {
    const field = getField(name)
    field.value = value
    if (options.changeEvent !== false) {
      field.dispatchEvent(new Event('change'))
    }
  }

  // Returns whether the checkbox with the given [name] is checked.
  function isChecked(name) {
    return getElement(getCheckboxSelector(name)).checked
  }

  // Shows or hides all elements in the given list.
  function toggleList(list, state) {
    list.forEach((element) => up.element.toggle(element, state))
  }

  async function scheduleRenderFragmentsFromServer(selectors) {
    // The given `selectors` need to be re-rendered by the server.
    // However, we don't make the server request right away.
    // This task may have pending callbacks that also want to render
    // additional selectors. Hence we collect all the selectors that
    // need to be re-rendered and make a single request in the next task
    for (const selector of selectors) {
      selectorsToUpdateFromServer.add(selector)
    }

    clearTimeout(serverUpdateTimer)
    serverUpdateTimer = setTimeout(renderFragmentsFromServerNow)
  }

  async function renderFragmentsFromServerNow() {
    const selectors = new Set(selectorsToUpdateFromServer)
    selectorsToUpdateFromServer.clear()
    const params = new URLSearchParams(new FormData(form)).toString()
    const url = '/activities/render_form' + '?' + params
    const response = await fetch(url, { method: 'post' })
    if (!response.ok) {
      throw new Error('Server error while updating activity form')
    }
    const text = await response.text()

    const parser = new DOMParser()
    const newDocument = parser.parseFromString(text, 'text/html')
    for (const selector of selectors) {
      const oldElement = form.querySelector(selector)
      if (!oldElement) {
        throw new Error(`Could not find ${selector} in current activity form`)
      }
      const newElement = newDocument.querySelector(selector)
      if (!newElement) {
        throw new Error(`Could not find ${selector} in server response`)
      }
      oldElement.replaceWith(newElement)
      console.log('[Activity form] Updated fragment %o from server', newElement)
    }

    updateLocalUI()
  }

  // When the project selection changed, we ask the server to re-render
  // all the fragments that depend on the project.
  function onProjectChanged() {
    scheduleRenderFragmentsFromServer([
      '#accounting_method',
      '#billability',
      '#project_rate',
      '#packaged_project_rate',
      '#activity_convention',
      '#budget_select_and_info',
      '#create_stoppwatch_button',
    ])
  }

  function onProjectRateChanged() {
    scheduleRenderFragmentsFromServer([
      '#project_rate',
    ])
  }

  // When we change a field that affects the billable amount, we ask the server
  // to re-render that fragment that contains the budget select and
  // the "remaining budget after this activity" amount.
  function updateBudgetSelectAndInfo() {
    scheduleRenderFragmentsFromServer([
      '#budget_select_and_info',
      '#create_stoppwatch_button',
    ])
  }

  function onUnitsInput() {
    debouncedUpdateRecommendedUnits()
    copyUnitsToBillableUnits()
    forgetOriginalUnroundedUnits()
  }

  function copyUnitsToBillableUnits() {
    const billableUnitsDiffer = isChecked('billable_units_differ')
    if (!billableUnitsDiffer) {
      setValue('billable_units', getValue('units'), { changeEvent: false })
    }
  }

  function updateRecommendedUnits() {
    scheduleRenderFragmentsFromServer([
      '.recommended-activity-units',
      '#budget_select_and_info',
    ])
  }

  const debouncedUpdateRecommendedUnits = _.debounce(updateRecommendedUnits, 300)

  function acceptRecommendedUnits() {
    const recommendation = getElement('.recommended-activity-units--value').innerText.trim()
    setValue('units', recommendation)
    copyUnitsToBillableUnits()
    forgetOriginalUnroundedUnits()
    updateBudgetSelectAndInfo()
    up.element.hide(getElement('.recommended-activity-units'))
  }

  // For some accounting methods we show a checkbox "Billable units differ".
  // When that is checked we show a second units input for the number of units
  // that should go on the invoice.
  function handleVisibilityOfBillableHours() {
    const billableUnitsDiffer = isChecked('billable_units_differ')

    if (getBillability() === 'not' || getAccountingMethod() === 'packaged' || getAccountingMethod() === 'money') {
      up.element.hide(getElement('#billable_units_differ_group'))
      up.element.hide(getElement('#billable_units_group'))
    } else {
      up.element.show(getElement('#billable_units_differ_group'))
      up.element.toggle(getElement('#billable_units_group'), billableUnitsDiffer)
    }
  }

  function getAccountingMethod() {
    return getValue('accounting_method')
  }

  function getBillability() {
    return getValue('billability')
  }

  // When the accounting method changed from hourly to daily (or vice versa)
  // we convert the entered units using a factor of 8.
  // We cannot convert any other changes of accounting methods.
  function convertUnitsIntoNewAccountingMethod() {
    const accountingMethod = getAccountingMethod()

    if (accountingMethod === previousAccountingMethod) {
      return
    }

    const rawUnits = getValue('original_unrounded_units') || getValue('units')
    const units = parseFloat(rawUnits.replace(',', '.'))
    let convertedUnits

    if (!isNaN(units)) {
      if (previousAccountingMethod === 'hourly' && accountingMethod === 'daily') {
        convertedUnits = units / 8.0
      } else if (previousAccountingMethod === 'daily' && accountingMethod === 'hourly') {
        convertedUnits = units * 8.0
      }
    }

    if (!isNaN(convertedUnits)) {
      convertedUnits = convertedUnits.toString().replace('.', ',')
      setValue('units', convertedUnits)
    } else {
      console.log("[Activity form] Cannot convert unit '%s' from %s to %s", rawUnits, previousAccountingMethod, accountingMethod)
    }

    // The original_unrounded_units are now in the wrong accounting methods.
    // However, since we did the conversion based on that original value,
    // we no longer have a rounded value on the screen and can forget the original value.
    forgetOriginalUnroundedUnits()

    previousAccountingMethod = accountingMethod

    updateLocalUI()
  }

  function onAccountingMethodChanged() {
    convertUnitsIntoNewAccountingMethod()
    updateRecommendedUnits()
  }

  function onDateChanged() {
    scheduleRenderFragmentsFromServer([
      '#activity-date .form-annotation',
    ])
  }

  function forgetOriginalUnroundedUnits() {
    setValue('original_unrounded_units', '')
  }

  // We warn/confirm that the selected accounting method is the
  // project's preferred accounting method.
  function toggleAccountingMethodAnnotation() {
    const annotation = getElement('.activity-accounting-method-annotation')
    const defaultAccountingMethod = annotation.getAttribute('data-default')
    const defaultClass = '-success'
    const isDefault = (getAccountingMethod() === defaultAccountingMethod)

    if (defaultAccountingMethod) {
      up.element.show(annotation)
      annotation.classList.toggle(defaultClass, isDefault)
    } else {
      up.element.hide(annotation)
    }
  }

  // If there's only a single project rate available for the selected accounting method,
  // we select that accounting method.
  function selectSingleProjectRate() {
    const container = getAccountingMethod() === 'packaged' ? getElement('#packaged_project_rate') : getElement('#project_rate')
    const rateInputs = Array.from(container.querySelectorAll('input[type=radio]'))
    const visibleRateInputs = rateInputs.filter((input) => !input.classList.contains('d-none'))

    if (visibleRateInputs.length === 1) {
      visibleRateInputs[0].checked = true
    }
  }

  // Toggles elements that are only visible if the current accounting method
  // is or isn't a given value.
  function toggleAccountingMethodDependentElements() {
    const accountingMethod = getAccountingMethod()

    const isPackaged = (accountingMethod === 'packaged')
    const isMoney = (accountingMethod === 'money')

    form.querySelectorAll('.non_money, .packaged, .non_packaged').forEach((element) => {
      let show = true
      if (element.matches('.non_money')) {
        show &&= !isMoney
      }
      if (element.matches('.packaged')) {
        show &&= isPackaged
      }
      if (element.matches('.non_packaged')) {
        show &&= !isPackaged
      }
      up.element.toggle(element, show)
    })
  }

  // We re-label some fields when the accounting method changes.
  // This is only optics for the user, it does not change any field values.
  function setAccountingMethodDependentLabels() {
    let element
    switch (getAccountingMethod()) {
      case 'daily': {
        getElement('.humanized_accounting_method').innerText = 'Tagessätze'
        getElement('label[for="activity_billable_units_differ"]').innerText = 'abrechenbare Tagessätze weichen ab'
        getElement('label[for="activity_billable_units"]').innerText = 'Abrechenbare Tagessätze'
        if ((element = getElement('label[for="activity_project_rate_id"]')) != null) {
          element.innerText = 'Tagessatz'
        }
        if ((element = getElement('.recommended-activity-units--label')) != null) {
          element.innerText = 'Tagessätze'
        }
        break
      }
      case 'hourly': {
        getElement('.humanized_accounting_method').innerText = 'Stunden'
        getElement('label[for="activity_billable_units_differ"]').innerText = 'abrechenbare Stunden weichen ab'
        getElement('label[for="activity_billable_units"]').innerText = 'Abrechenbare Stunden'
        if ((element = getElement('label[for="activity_project_rate_id"]')) != null) {
          element.innerText = 'Stundensatz'
        }
        if ((element = getElement('.recommended-activity-units--label')) != null) {
          element.innerText = 'Stunden'
        }
        break
      }
      case 'packaged': {
        getElement('.humanized_accounting_method').innerText = 'Stück'
        if ((element = getElement('.recommended-activity-units--label')) != null) {
          element.innerText = 'Stück'
        }
        break
      }
      case 'money': {
        getElement('.humanized_accounting_method').innerText = '€ netto'
        if ((element = getElement('.recommended-activity-units--label')) != null) {
          element.innerText = '€'
        }
        break
      }
    }
  }

  // Updates the form using frontend rules, without contacting the server.
  // This mostly updates visibility and labels.
  function updateLocalUI() {
    handleVisibilityOfBillableHours()
    setAccountingMethodDependentLabels()
    toggleAccountingMethodAnnotation()
    toggleAccountingMethodDependentElements()
    selectSingleProjectRate()
    toggleMoreOptions()
  }

  // Shows or hides containers with rarely used fields.
  function toggleMoreOptions() {
    const options = form.querySelectorAll('.more_options')
    const hasError = !!form.querySelector('.more_options .is-invalid')

    // If any options container contains an error, we open all the containers.
    showMore ||= hasError

    toggleList(options, showMore)
    up.element.toggle(getElement('.show_more_link'), !showMore)
  }

  function onShowMoreClicked() {
    showMore = true
    updateLocalUI()
  }

  up.on(form, 'click', '.show_more_link', onShowMoreClicked)
  up.on(form, 'change', '#activity_project_id', onProjectChanged)
  up.on(form, 'change', '#project_rate', onProjectRateChanged)
  up.on(form, 'change', '[data-requires-budget-update]', updateBudgetSelectAndInfo)
  up.on(form, 'change', '#activity_accounting_method', onAccountingMethodChanged)
  up.on(form, 'change', '#activity_date', onDateChanged)
  up.on(form, 'input', '#activity_units', onUnitsInput)
  up.on(form, 'click', '.recommended-activity-units', acceptRecommendedUnits)
  up.on(form, 'change', '#activity_billability, #activity_accounting_method, #activity_billable_units_differ', handleVisibilityOfBillableHours)

  updateLocalUI()
}

up.compiler('#activity_form', function(form) {
  initActivityForm(form)
})
