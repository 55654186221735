up.compiler('.flashes', (element) => {
  function fadeOut() {
    up.animate(element, 'fade-out', { duration: 1000 }).then(() => element.remove())
  }

  const timeoutId = window.setTimeout(fadeOut, 2000)

  return function() {
    window.clearTimeout(timeoutId)
  }
})
