up.compiler('.business_plan_form__add_fields', (element, { association, fields }) => {
  element.addEventListener('click', () => {
    const childIndex = new Date().getTime()
    const childIndexRegexp = new RegExp('new_' + association, 'g')
    const newElement = fields.replace(childIndexRegexp, childIndex)

    const associationHeader = document.querySelector('#' + association + '_header')
    associationHeader.insertAdjacentHTML('afterend', newElement)
    const firstNewElement = associationHeader.nextSibling

    // Two new elements get inserted. Say hello to both
    up.hello(firstNewElement)
    up.hello(firstNewElement.nextElementSibling)
  })
})

up.compiler('.business_plan_form__remove_fields', (element) => {
  const realFields = element.closest('.business-plan--item-fields-real')
  const plannedFields = realFields.nextElementSibling

  element.addEventListener('click', () => {
    realFields.querySelector('.business_plan_form__destroy').value = '1'
    up.element.hide(realFields)
    up.element.hide(plannedFields)
  })
})
