up.compiler('form', (form) => {
  function hideNestedRowsForDestruction(_evnt, destroyButton) {
    if (confirm('Diesen Eintrag löschen?')) {
      const row = destroyButton.closest('tr')
      row.querySelector('input.destruction-marker').value = '1'
      up.element.hide(row)
    }
  }

  up.on(form, 'click', '.destruction-icon', hideNestedRowsForDestruction)
})
