up.compiler('[session-check="true"]', () => {
  async function checkForSession() {
    const response = await fetch('/session_status')

    if (!response.ok) {
      window.location.reload()
    }
  }

  up.on(window, 'focus', checkForSession)
})
