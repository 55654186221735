up.compiler('textarea', (textarea) => {
  if (textarea.id) {
    const printVersionID = textarea.id + '_for_print'
    const printVersion = up.element.createFromSelector('div', { class: ['form-control', 'd-none', 'd-print-block'], id: printVersionID })
    const textWrapperElement = document.createElement('p')
    textarea.parentNode.insertBefore(printVersion, textarea)
    printVersion.append(textWrapperElement)

    textarea.addEventListener('change', (event) => {
      textWrapperElement.innerText = textarea.value
    })
  }
})
