import flatpickr from 'flatpickr/dist/esm'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/light.css'
const German = require('flatpickr/dist/l10n/de.js').default.de
flatpickr.localize(German)

up.compiler('[date-picker]', (element) => {
  // Don't use flatpickr if date-picker is disabled
  if (document.body.dataset.featureDatePicker === 'false') return

  const labels = Array.from(element.labels) // fetch labels now, because flatpickr turns it into type "hidden" (which can't have labels)
  const hiddenInputName = element.name

  const picker = flatpickr(element, {
    allowInput: true,
    altInput: true,
    altFormat: 'd.m.Y',
    dateFormat: 'd.m.Y',
    locale: 'de',
    parseDate: (dateString) => {
      const match = /^(?<day>\d\d?)\.(?<month>\d\d?)\.(?<year>\d{4})$/.exec(dateString)
      if (!match) {
        // This will make the calling function throw an error, but set the value correctly to "undefined".
        return null
      } else {
        const { day, month, year } = match.groups
        const normalizedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        return new Date(normalizedDate)
      }
    },
    errorHandler: (error) => {
      // We suppress errors from our parseDate method here.
      if (!error.message.startsWith('Invalid date provided: ')) {
        // Default error handling (copied from flatpickr source) in here:
        return typeof console !== 'undefined' && console.warn(error)
      }
    },
  })

  const altInput = picker.altInput

  // Helper class and attribute for tests
  altInput.classList.add('date-picker--user-input')
  altInput.setAttribute('hidden-input-name', hiddenInputName)

  // Update label(s) to point to user input, instead of the (now hidden) `element`
  if (!altInput.id) {
    altInput.setAttribute('id', `date-picker-${Math.random().toString(36).replace(/^0\./, '')}`)
  }
  labels.forEach((label) => {
    label.setAttribute('for', altInput.id)
  })

  return () => {
    picker.destroy()
  }
})
