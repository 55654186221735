const Tooltip = {

  MOUSE_OFFSET_X: 6,
  MOUSE_OFFSET_Y: 6,
  ELEMENT_OFFSET_X: 6,
  ELEMENT_OFFSET_Y: 6,
  OPACITY: 0.9,

  element: function() {
    if (!this._element || !this._element.isConnected) {
      this._element = up.element.affix(document.body, 'div', { id: 'tooltip', class: 'tooltip' })
      this._element.innerText = 'hello'
    }
    return this._element
  },

  autoConnect: function(trigger) {
    const tooltipText = trigger.dataset.tooltip
    if (tooltipText) {
      if (document.body.dataset.railsEnv !== 'test') {
        trigger.dataset.tooltip = ''
        trigger.setAttribute('title', '')
      }
      this.connect(trigger, tooltipText)
    }
  },

  connect: function(trigger, content) {
    trigger.addEventListener('mouseover', function(event) {
      this.show(trigger, event, content)
    }.bind(this))
    trigger.addEventListener('mouseout', this.hide.bind(this))
  },

  show: function(trigger, event, content) {
    if (!trigger.dragging) {
      if (typeof content === 'function') {
        content = content()
      }
      if (content) {
        const element = this.element()
        up.element.show(element)

        if (this.lastContent !== content) {
          this.lastContent = content
          element.innerHTML = content
          up.element.setStyle(element, {
            left: '-10000px',
            top: '-10000px',
            position: 'absolute',
            opacity: this.OPACITY,
          })

          // The browser has to render the tooltip so we can get the correct size
          setTimeout(() => {
            const pos = this.tooltipPosition(trigger, event)
            up.element.setStyle(element, { left: pos.left + 'px', top: pos.top + 'px' })
          })
        } else {
          const pos = this.tooltipPosition(trigger, event)
          up.element.setStyle(element, { left: pos.left + 'px', top: pos.top + 'px' })
        }

        // fix: prevent that a parent tooltip gets invoked after bubbling up
        event.stopPropagation()
      }
    }
  },

  tooltipPosition: function(trigger, event) {
    const triggerRect = trigger.getBoundingClientRect()
    const elementRect = this.element().getBoundingClientRect()
    const mouseX = event.pageX
    const mouseY = event.pageY
    let tooltipX
    let tooltipY

    const tooltipAnchorX = trigger.dataset.tooltipX || 'element-right'

    switch (tooltipAnchorX) {
      case 'element-right':
        tooltipX = triggerRect.right + this.ELEMENT_OFFSET_X + window.scrollX
        break
      case 'mouse-right':
        tooltipX = mouseX + this.MOUSE_OFFSET_X
        break
      case 'element-left':
        tooltipX = triggerRect.left - this.ELEMENT_OFFSET_X - elementRect.width + window.scrollX
        break
    }

    const tooltipAnchorY = trigger.dataset.tooltipY || 'element-bottom'

    switch (tooltipAnchorY) {
      case 'element-top':
        tooltipY = triggerRect.top - elementRect.height - this.ELEMENT_OFFSET_Y + window.scrollY
        break
      case 'element-bottom':
        tooltipY = triggerRect.bottom + this.ELEMENT_OFFSET_Y + window.scrollY
        break
      case 'mouse-top':
        tooltipY = mouseY - elementRect.height - this.ELEMENT_OFFSET_Y
        break
      case 'mouse-bottom':
        tooltipY = mouseY + this.ELEMENT_OFFSET_Y
        break
    }

    return { left: tooltipX, top: tooltipY }
  },

  hide: function() {
    up.element.hide(this.element())
  },

}

up.compiler('[data-tooltip]', element => {
  Tooltip.autoConnect(element)
})

function rootUrl() {
  return document.body.dataset.root
}

const Layouter = {

  connect: function() {
    if (document.querySelector('#content_container')) {
      this.resizeContent()
      window.addEventListener('resize', Layouter.resizeContent.bind(this))
    }
  },

  resizeContent: function() {
    const contentContainer = document.querySelector('#content_container')
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const powerBarHeight = document.querySelector('#power_bar') ? document.querySelector('#power_bar').clientHeight : 0
    const contentOffset = contentContainer.getBoundingClientRect().top
    const contentHeight = windowHeight - contentOffset - powerBarHeight
    contentContainer.style.height = '' + contentHeight + 'px'
  },

}

up.compiler('#content_container', Layouter.connect.bind(Layouter))

class Worker {
  constructor(options) {
    this.jobs = []
    this.afterUpdate = options.afterUpdate || function(current, total) {}
    this.afterFinish = options.afterFinish || function() {}
  }

  queue(job) {
    if (this.finished) {
      throw new Error('Cannot assign jobs to a finished worker')
    }
    this.jobs.push(job)
  }

  start() {
    this.currentIndex = 0
    this.work()
  }

  work() {
    if (this.currentIndex < this.jobs.length) {
      this.afterUpdate(this.currentIndex, this.jobs.length)
      this.jobs[this.currentIndex]()
      this.currentIndex += 1
      setTimeout(this.work.bind(this)) // Use a timeout so that the browser can do other stuff while working on this task
    } else {
      this.afterUpdate(this.currentIndex, this.jobs.length)
      this.finish()
    }
  }

  finish() {
    this.queue = null
    this.finished = true
    this.afterFinish()
  }
}

// select tag
function setSelectedOption(selectTag, option) {
  option.selected = true // responsible for Element.getValue() to return the set option
  // reset selected attribute
  selectTag.querySelectorAll('option[selected]').forEach(function(option) {
    option.removeAttribute('selected')
  })
  option.setAttribute('selected', 'selected')
}

function suggestBudgetShortName(nameInput) {
  const name = nameInput.value
  const shortNameInput = nameInput.closest('.budget').querySelector('.short_name input')
  if (shortNameInput.value === '') {
    shortNameInput.value = name.substr(0, 4)
  }
}

window.Worker = Worker
window.Tooltip = Tooltip
window.setSelectedOption = setSelectedOption
window.rootUrl = rootUrl
window.suggestBudgetShortName = suggestBudgetShortName
