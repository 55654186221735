import TailSelect from 'tail.select.js/js/tail.select-full'
import 'tail.select.js/langs/tail.select-de'
import 'tail.select.js/css/default/tail.select-light.css'

up.compiler('.searchable-select', (nativeSelect) => {
  const errors = nativeSelect.getAttribute('tail-select-errors')
  const tailSelect = TailSelect(nativeSelect, { search: true, locale: 'de', classNames: 'style-overwrite' })

  tailSelect.label.classList.add('form-select')
  tailSelect.label.classList.remove('select-label')
  tailSelect.label.tabIndex = '0'

  if (errors === 'true') {
    tailSelect.label.classList.add('is-invalid')
  }

  // There are several fields that depend on the project select (see onProjectChanged(), activity_form.js).
  // As soon as the project select changes, the dependent fields should be updated and rendered properly.
  // Using tail.select, the 'change' event is no longer emitted on the native select, which in turn prevents the
  // dependent fields from being updated properly. This is why we have to manually emit the 'change' event on the native select.
  tailSelect.on('change', function() {
    up.emit('change', { target: nativeSelect })
  })

  nativeSelect.addEventListener('tail.select:source:change', function(event) {
    const selectedOption = nativeSelect.options[nativeSelect.selectedIndex]
    tailSelect.label.childNodes[0].innerText = selectedOption ? selectedOption.label : ''
  })
})
