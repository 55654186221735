up.compiler('.filter-form', (formElement) => {
  const toggleElement = formElement.querySelector('.show-filter-link')
  const closeElement = formElement.querySelector('.close-filter-link')

  if (!toggleElement || !closeElement) return

  function toggleFilter() {
    const filter = formElement.querySelector('#filter_wrapper')
    up.element.toggle(filter)
  }

  function hideFilter() {
    const filter = formElement.querySelector('#filter_wrapper')
    up.element.hide(filter)
  }

  toggleElement.addEventListener('click', toggleFilter)
  closeElement.addEventListener('click', hideFilter)

  hideFilter()
})
