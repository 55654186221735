up.compiler('#revenue_report', (revenueReport) => {
  const hideDetailsLink = revenueReport.querySelector('#hide_details_link')
  const showDetailsLink = revenueReport.querySelector('#show_details_link')

  function hideDetails() {
    revenueReport.querySelectorAll('.details').forEach(up.element.hide)
    up.element.hide(revenueReport.querySelector('#hide_details_link'))
    up.element.show(revenueReport.querySelector('#show_details_link'))
  }

  function showDetails() {
    revenueReport.querySelectorAll('.details').forEach(up.element.show)
    up.element.hide(revenueReport.querySelector('#show_details_link'))
    up.element.show(revenueReport.querySelector('#hide_details_link'))
  }

  up.on(hideDetailsLink, 'click', hideDetails)
  up.on(showDetailsLink, 'click', showDetails)
})
