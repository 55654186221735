up.compiler('.invoice-states', (element) => {
  const hiddenStateinput = document.getElementById('hidden_invoice_state')
  const invoiceStateItems = element.querySelectorAll('.invoice-state-item')

  function updateWidget() {
    invoiceStateItems.forEach((item) => {
      if (item.getAttribute('data-state') === hiddenStateinput.value) {
        item.classList.add('-current')
      } else {
        item.classList.remove('-current')
      }
    })

    updateActiveStates()
  }

  function updateActiveStates() {
    const currentItem = element.querySelector('.invoice-state-item.-current')

    activateStatesUpTo(currentItem)
  }

  function activateStatesUpTo(item) {
    /* Reset all active styles */
    invoiceStateItems.forEach((item) => {
      item.classList.remove('-active')
    })

    /* Apply active styles */
    let currentItem = item
    while (currentItem) {
      currentItem.classList.add('-active')
      currentItem = currentItem.previousElementSibling
    }

    // cancel state monkey patch
    // if current state == 'canceled'
    if (item.innerHTML.match(/storniert/) !== null) {
      // remove active from the element with the state 'paid'

      let currentItem = item
      while (currentItem) {
        if (currentItem.innerHTML.match(/bezahlt/) !== null) {
          currentItem.classList.remove('-active')
        }
        currentItem = currentItem.previousElementSibling
      }
    }
  }

  function initControls() {
    element.querySelectorAll('.control').forEach((controlElement) => {

      controlElement.addEventListener('selectstart', (e) => {
        e.preventDefault()
        e.stopPropagation()
      })

      controlElement.addEventListener('mousedown', (e) => {
        e.preventDefault()
        e.stopPropagation()
      })

      controlElement.addEventListener('click', () => {
        const currentItem = element.querySelector('.invoice-state-item.-current')
        let newCurrentItem

        if (controlElement.classList.contains('previous')) {
          newCurrentItem = currentItem.previousElementSibling
        } else if (controlElement.classList.contains('next')) {
          newCurrentItem = currentItem.nextElementSibling
        }

        if (newCurrentItem) {
          hiddenStateinput.value = newCurrentItem.getAttribute('data-state')
          updateWidget()
        }
      })
    })
  }

  updateWidget()
  initControls()
})
