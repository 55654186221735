up.compiler('.toggleable', (toggleable) => {
  let checkbox

  if (toggleable.getAttribute('toggleable-by')) {
    checkbox = document.querySelector(`${toggleable.getAttribute('toggleable-by')} .toggleable--checkbox`)
  } else {
    checkbox = toggleable.querySelector('.toggleable--checkbox')
  }

  checkbox.addEventListener('change', function(event) {
    const inverted = toggleable.classList.contains('-inverted')
    const ignoreWhenNotEmpty = toggleable.classList.contains('-ignore-when-not-empty')
    const show = inverted ? event.target.checked : !event.target.checked

    if (show) {
      const inputs = toggleable.querySelectorAll('.toggleable--content > input')
      let allEmpty = true

      for (const input of inputs) {
        if (input.value) { allEmpty = false }
      }
      if (!(ignoreWhenNotEmpty && !allEmpty)) { toggleable.classList.add('-hidden') }
    } else {
      toggleable.classList.remove('-hidden')
    }
  })
})
