import TomSelect from 'tom-select/dist/esm/tom-select'
import 'tom-select/dist/css/tom-select.css'
import './tom-select/**/*.sass'

up.compiler('.tom-selectable', function(select) {
  const config = {
    maxItems: 1,
    create: false,
    createOnBlur: true,
    render: {
      // i18n
      option_create: function(data, escape) {
        return '<div class="create"><strong>' + escape(data.input) + '</strong> eintragen&hellip;</div>'
      },
      no_results: function(data, escape) {
        return '<div class="no-results">Keine Ergebnisse gefunden</div>'
      },
    },
  }

  if (select.dataset.allowCreate) {
    Object.assign(config, {
      persist: false,
      create: true,
      createOnBlur: true,
    })
  }

  if (select.dataset.allowBlank) {
    Object.assign(config, {
      allowEmptyOption: true,
    })
  }

  if (select.dataset.loadPath) {
    Object.assign(config, {
      valueField: 'name',
      labelField: 'name',
      searchField: 'name',
      load: function(query, callback) {
        const url = rootUrl() + select.dataset.loadPath
        const params = { query }
        up.request(url, { params }).then(function(response) {
          const data = JSON.parse(response.text)
          callback(data)
        })
      },
    })
  }

  const tomselect = new TomSelect(select, config)

  if (select.dataset.onChangeRender) {
    const renderOptions = JSON.parse(select.dataset.onChangeRender)
    tomselect.on('change', async function(value) {
      const params = {}
      params[select.getAttribute('name')] = value
      renderOptions.params = params

      const request = up.request(renderOptions.url, renderOptions)
      const response = await request

      const newElement = up.element.createFromHTML(response.text)
      document.querySelector(renderOptions.target).replaceWith(newElement)
    })
  }
})
